import React, { useRef } from "react";
import "../css/Contact.css";
import emailjs from "@emailjs/browser";
import { notifications } from "@mantine/notifications";
import { FcApproval, FcHighPriority } from "react-icons/fc";
function Contact() {
  window.scrollTo(0, 0);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    const id = notifications.show({
      loading: true,
      title: "Mail sending.....",
      message: "Please wait....",
      autoClose: false,
      withCloseButton: false,
    });

    emailjs
      .sendForm("service_tvm17jd", "template_b43gra9", form.current, {
        publicKey: "om6ba35ThoFjGlH28",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          notifications.update({
            id,
            color: "teal",
            title: "Successful",
            message: "Mail send successfully",
            icon: <FcApproval />,
            loading: false,
            autoClose: 2000,
          });
        },
        (error) => {
          console.log("FAILED...", error.text);
          notifications.update({
            id,
            color: "red",
            title: "Wrong",
            message: "Something went wrong. Please try again",
            icon: <FcHighPriority />,
            loading: false,
            autoClose: 2000,
          });
        }
      );
  };

  return (
    <div className='container-fluid'>
      <div className='contact-main'>
        <div className='contact-left-flex'>
          <div>
            <h3>Contact Details</h3>
            <h4>A.R.M Texttiles</h4>
          </div>
          <div className='contact-details'>
            <h5>Mr. R. Singaravel (MD) - 9894741962</h5>
            <h5>Mr. S. Dinesh (JMD) - 9047080962</h5>
            <h5>Mr. S. Harish (CEO) - 9843031962</h5>
            <h5>Mr. S. Raajesh (GM) - 9994990962</h5>
          </div>
          <div className='contact-address'>
            <p>
              <span>Ph :</span> +(91)-4324-230962, 230466
            </p>
            <p>
              <span>Email :</span> arm@armtex.in
            </p>
            <p>
              <span>Address :</span> 9D/15, Ramakrishnapuram North Karur -
              639001, Tamil Nadu, India
            </p>
          </div>
        </div>
        <div className='contact-right-flex'>
          <h4>Enquiry Form</h4>
          <div className='enquiry-input'>
            <form
              id='input-form'
              onSubmit={sendEmail}
              ref={form}
            >
              <div>
                <input
                  className='form-input'
                  placeholder='Name'
                  name='from_name'
                />
                <input
                  className='form-input'
                  placeholder='Email'
                  name='from_email'
                  type='text'
                />
              </div>
              <div>
                <input
                  className='form-input'
                  placeholder='Phone'
                  name="from_phone"
                />
                <input
                  className='form-input'
                  placeholder='Location'
                  name="from_location"
                />
              </div>
              <div>
                <input
                  className='form-input-two'
                  name='message'
                  placeholder='Enter your requirement in detail'
                />
              </div>
              <div className='submit'>
                <button
                  className='submit-btn'
                  type='submit'
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
