import React from "react";
import image1 from "../Assets/products/Apron.jpg";

function Apron() {
  return (
    <div className="apron-main">
      <img
        src={image1}
        style={{
          width: "100%",
          objectFit: "contain",
          height: "90vh",
        }}
      ></img>
    </div>
  );
}
export default Apron;
