import React from "react";
import certificate4 from "../Assets/certificateFour.jpg";

function Certificate4() {
  window.scrollTo(0, 0);

  return (
    <div className="cert-full-one">
      <img style={{ width: "100%" }} src={certificate4}></img>
    </div>
  );
}
export default Certificate4;
