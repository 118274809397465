import React from "react";
import "../css/Specification.css";
import product1 from "../Assets/Product-Specification/Cushion/Cushion (1).jpg";
import product2 from "../Assets/Product-Specification/Cushion/Cushion (2).jpg";
import product3 from "../Assets/Product-Specification/Cushion/Cushion (3).jpg";
import product4 from "../Assets/Product-Specification/Cushion/Cushion (4).jpg";
import product5 from "../Assets/Product-Specification/Cushion/Cushion (5).jpg";

function Cushion() {
  window.scrollTo(0, 0);
  return (
    <div className="specification">
      <div>
        <h3>Cushion</h3>
        <div className="product-img-flex">
          <img src={product1} />
          <img src={product2} />
          <img src={product3} />
          <img src={product4} />
          <img src={product5} />
        </div>
      </div>
    </div>
  );
}
export default Cushion;
